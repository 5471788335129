<template>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessRepresentative"
      v-if="business.representative != undefined"
    >
      <app-form-input
        id="business.representative.first_name"
        name="business.representative.first_name"
        type="text"
        :placeholder="$t('business.representative.first_name_placeholder')"
        :labelText="$t('business.representative.legal_name')"
        v-model="business.representative.first_name"
        :errorMessage="errors.first_name"
        :disabled="editMode"
      />
      <app-form-input
        id="business.representative.last_name"
        name="business.representative.last_name"
        type="text"
        :placeholder="$t('business.representative.last_name_placeholder')"
        class="mb-5"
        v-model="business.representative.last_name"
        :errorMessage="errors.last_name"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.email"
        name="business.representative.email"
        type="email"
        :labelText="$t('business.representative.email')"
        class="w-full mb-5"
        v-model="business.representative.email"
        :errorMessage="errors.email"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.designation"
        name="business.representative.designation"
        type="text"
        class="w-full mb-5"
        :placeholder="$t('business.representative.job_position_placeholder')"
        :labelText="$t('business.representative.designation')"
        v-model="business.representative.designation"
        :errorMessage="errors.designation"
        :disabled="editMode"
      />

      <app-form-input
        type="date"
        id="business.representative.date_of_birth"
        name="business.representative.date_of_birth"
        :labelText="$t('business.representative.date_of_birth')"
        :placeholder="$t('business.representative.date_placeholder')"
        class="w-full mb-5"
        v-model="business.representative.date_of_birth"
        :errorMessage="errors.date_of_birth"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.address_1"
        name="business.representative.address_1"
        type="text"
        :placeholder="$t('business.representative.address_placeholder_1')"
        :labelText="$t('business.representative.home_address')"
        class="w-full mb-1"
        v-model="business.representative.address_1"
        :errorMessage="errors.address_1"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.address_2"
        name="business.representative.address_2"
        type="text"
        :placeholder="$t('business.representative.address_placeholder_2')"
        class="w-full mb-1"
        v-model="business.representative.address_2"
        :errorMessage="errors.address_2"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.postcode"
        name="business.representative.postcode"
        type="number"
        :placeholder="$t('business.representative.postcode_placeholder')"
        class="w-full mb-1"
        v-model="business.representative.postcode"
        :errorMessage="errors.postcode"
        :disabled="editMode"
        :numberOnly="true"
      />

      <app-form-input
        id="business.representative.city"
        name="business.representative.city"
        type="text"
        :placeholder="$t('business.representative.city_placeholder')"
        class="w-full mb-1"
        v-model="business.representative.city"
        :errorMessage="errors.city"
        :disabled="editMode"
      />

      <app-form-country
        :allowFilter="true"
        :showLabel="false"
        :placeholder="$t('general.country')"
        class="w-full mb-1"
        v-model="business.representative.country"
        :errorMessage="errors.country_id"
        :disabled="editMode"
      />

      <app-form-state
        :showLabel="false"
        class="w-full mb-5"
        :placeholder="$t('general.address.state')"
        :country_id="business.representative.country?.id ?? 0"
        v-model="business.representative.state"
        :errorMessage="errors.state_id"
        :disabled="editMode"
      />

      <app-form-telephone-no
        name="business.representative.phone_no"
        :labelText="$t('business.representative.phone_no')"
        class="w-full mb-5"
        v-model="business.representative.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="MyKad"
            v-model="business.representative.identification_type"
            checked
            :disabled="editMode"
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="Passport"
            v-model="business.representative.identification_type"
            :disabled="editMode"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col w-full mb-10">
        <app-form-input
          id="business.representative.identification"
          name="business.representative.identification"
          :labelText="labelIdentification()"
          v-model="business.representative.identification"
          :errorMessage="errors.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :disabled="editMode"
          :numberOnly="ic_type_input.type === 'number'"
        />
      </div>

      <div v-if="!editMode">
        <div class="my-10">
          <app-upload-support-document
            class="mb-5 w-full"
            v-for="(documents, name) in representative_ic_documents"
            :max="max"
            :key="name"
            :title="name"
            :errors="errors"
            @refresh="refresh"
            @changed="changed"
            @delete="deleteDocument"
            :disabled="editMode"
            :documents="documents"
            :type="representative_ic_documents_types"
            rule="required"
          />
        </div>
      </div>
      <div v-else>
        <div class="w-full space-y-5 mb-10">
          <div
            v-for="(documents, name) in representative_ic_documents"
            :key="name"
          >
            <p class="uppercase text-xs pb-3">
              {{ name?.split("_").join(" ") }}
            </p>
            <div v-if="$lodash.isEmpty(documents)">
              <div
                class="
                  py-2
                  px-4
                  items-start
                  justify-between
                bg-gray-100
                  border-b border-gray-200
                  border-t
                "
              >
                <div class="flex-1 flex items-left items-center space-x-5">
                  <p class="text-sm">{{ $t("business.representative.no_file_found") }}</p>
                </div>
              </div>
            </div>
            <div
              v-for="(document, index) in documents"
              :key="index"
              class="
                py-2
                px-4
                flex flex-col
                sm:flex-row sm:space-x-3
                space-x-0
                sm:space-y-0
                space-y-3
                sm:items-center
                items-start
                justify-between
                hover:bg-gray-100
                border-b border-gray-200
              "
              :class="{ 'border-t': index == 0 }"
            >
              <div class="flex-1 flex items-left items-center space-x-5">
                <app-icon-jpg
                  v-if="
                    document.mime_type.includes('jpeg') ||
                    document.mime_type.includes('jpg')
                  "
                />
                <app-icon-png v-else-if="document.mime_type.includes('png')" />
                <app-icon-pdf v-else-if="document.mime_type.includes('pdf')" />
                <p class="text-sm">
                  {{ document.file_name }}
                </p>
              </div>

              <div class="flex space-x-5">
                <p class="text-xs text-gray-500 mt-1">
                  {{ Math.round(document.size / 1024) }} KB
                </p>
                <a
                  :href="document.original_url"
                  class="flex space-x-1 items-center cursor-pointer text-black"
                  target="_blank"
                >
                  <p class="text-sm">{{ $t("general.view") }}</p>
                </a>
                <a
                  class="flex space-x-1 items-center cursor-pointer text-black"
                  @click="download(document)"
                >
                  <p class="text-sm">{{ $t("general.download") }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-button
        type="submit"
        v-if="!editMode"
        :showf70Icon="false"
        class="mt-3 w-full"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      ic_type: "MyKad",
      ic_type_input: null,
      max: 5,
      files: {},
      unsaved: false,
      emptyErrors: {
        full_name: [],
        email: [],
        date_of_birth: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        country_id: [],
        state_id: [],
        phone_no: [],
        identification_type: [],
        identification: [],
        document_representative_ic_copy: [],
        document_representative_ic_copy_back: [],
      },
    };
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
    ic_type() {
      this.labelIdentification();
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    representative_ic_documents() {
      return this.$store.getters["businessStore/representative_ic_documents"];
    },

    representative_ic_documents_types() {
      return this.$store.getters[
        "businessStore/representative_ic_documents_types"
      ];
    },

    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },
  mounted() {
    this.ic_type = this.business.representative?.identification_type ?? this.ic_type;
    this.selectInputType();
  },
  beforeCreate() {
    this.$store.dispatch("businessStore/getRepresentativeIcDocuments", this.$route.params.business_id);
    this.$store.dispatch("businessStore/getRepresentativeIcDocumentsTypes", this.$route.params.business_id);
  },
  methods: {
    onSelected(event) {
      this.ic_type = event.target.value;
      this.business.representative.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: null,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    async submitBusinessRepresentative() {
      this.resetState();

      let representative = Object.assign(
        this.business.representative,
        this.files
      );

      const businessRepresentative = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessRepresentative",
        representative
      );

      if (businessRepresentative != null) {
        this.hasChanged = false;
        this.refresh();
        this.editMode = !this.editMode;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },
    changed(file, title) {
      this.hasChanged = true;
      this.unsaved = true;
      Object.assign(this.files, { [title]: file });
    },
    download(media) {
      axios
        .get(media.original_url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: media.mime_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = media.file_name;
          link.click();
        })
        .catch((error) => {
          // if has been blocked by CORS redirect to original url
          window.location.href = media.original_url;
        });
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
    refresh() {
      this.$store.dispatch(
        "businessStore/getRepresentativeIcDocuments",
        this.$route.params.business_id
      );
      this.$store.dispatch(
        "businessStore/getRepresentativeIcDocumentsTypes",
        this.$route.params.business_id
      );
    },
    async deleteDocument(document) {
      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "mediaStore/deleteMedia",
          document._id
        );
      }

      this.refresh();
    },
  },
};
</script>
